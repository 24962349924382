import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

interface ILink {
  href: string;
  label: string;
  ariaLabel: string;
}

interface IProps {
  onClick?: () => void;
  className?: string;
  links: ILink[];
}

export const Links: React.FC<IProps> = ({ onClick, className, links }) => {
  return (
    <ul
      aria-label='Links'
      className={className}
    >
      {links.map((link) => (
        <li key={link.label}>
          <AnchorLink
            onAnchorLinkClick={onClick}
            to={link.href}
            gatsbyLinkProps={{ 'aria-label': link.ariaLabel }}
            className={`paragraph hover:text-black-100`}
          >
            {link.label}
          </AnchorLink>
        </li>
      ))}
    </ul>
  );
};
