import React, { useState } from 'react';
import { ALL, IMenuItem, MENU_CATEGORIES, MENU_ITEMS } from '../../config/menu';
import { Filters } from './filters';
import { Items } from './items';

const filterMenuItems = (category: string, menuItems: IMenuItem[]) => {
  return menuItems.filter((item) => item.category === category || category === ALL);
};

export const Menu = () => {
  const [activeCategory, setActiveCategory] = useState(ALL);
  const [menuItems, setMenuItems] = useState(filterMenuItems(activeCategory, MENU_ITEMS));

  const changeActiveFilter = (newCategory: string) => {
    if (activeCategory !== newCategory) {
      setActiveCategory(newCategory);
      setMenuItems(filterMenuItems(newCategory, MENU_ITEMS));
    }
  };

  return (
    <section
      id='menu'
      className='bg-chestnut py-10 container-x flex flex-col sm:items-center'
    >
      <h2 className='heading-1 mb-5 text-white sm:text-center'>Our Menu</h2>
      <Filters
        activeFilter={activeCategory}
        filters={MENU_CATEGORIES}
        onChange={changeActiveFilter}
      />
      <Items
        activeFilter={activeCategory}
        items={menuItems}
      />
    </section>
  );
};
