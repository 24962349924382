import { graphql, useStaticQuery } from 'gatsby';

interface SiteMetadata {
  title: string;
  siteUrl: string;
  previewImageURL: string;
  faviconURL: string;
}

interface SiteMetadataQueryResult {
  site: {
    siteMetadata: SiteMetadata;
  };
}

const useSiteMetadata = () => {
  const data = useStaticQuery<SiteMetadataQueryResult>(graphql`
    query {
      site {
        siteMetadata {
          title
          previewImageURL
          siteUrl
          faviconURL
        }
      }
    }
  `);

  return data.site.siteMetadata;
};

export default useSiteMetadata;
