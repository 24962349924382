import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useAnimateOnVisible, useMediaQuery } from '../../hooks';

export const Hero = () => {
  useAnimateOnVisible({
    animations: [
      {
        animationClassName: 'animate__slideInLeft',
        elementId: 'hero-title',
        containerId: 'hero-header'
      },
      {
        animationClassName: 'animate__slideInLeft',
        elementId: 'satisfied-guests',
        containerId: 'stats-overview'
      }
    ]
  });
  const loadImage = useMediaQuery('(min-width: 768px)');

  return (
    <section className='flex short:min-h-[calc(100vh-85px)] tall:min-h-[calc(55vh)]'>
      <div className='flex flex-col md:w-[calc((100%-40px)*0.50+20px)] lg:w-[calc((100%-200px)*0.50+100px)]'>
        <div
          id='hero-header'
          className='container-x md:pr-0 lg:pt-5 md:w-[calc(100%-40px)] lg:w-[calc(100%-70px)] mb-[30px] short:mb-[0px] overflow-hidden'
        >
          <h1
            id='hero-title'
            className='heading-1 text-black-100 motion-safe:invisible animate__animated'
          >
            Savor the <span className='block'>Essence of Japan</span>
          </h1>
          <p className='paragraph mt-5 text-gray-550'>
            Immerse yourself in a culinary adventure with handcrafted sushi, savory ramen, and time-honored Japanese
            dishes, all made with authentic ingredients and artful expertise
          </p>
        </div>
        <div className='bg-beige mt-auto'>
          <div
            id='stats-overview'
            className='flex flex-col xl:flex-row gap-2 xl:gap-3 xl:items-center container-x py-2.5 md:pr-2.5 overflow-hidden'
          >
            <div
              id='satisfied-guests'
              className='flex items-center motion-safe:invisible animate__animated'
            >
              <span
                className='heading-1 text-black-100'
                aria-label='Over 24000'
              >
                24K+
              </span>
              <div className='caption text-gray-550 mx-3'>
                Satisfied
                <span className='xl:hidden'> </span>
                <span className='inline xl:block'>Guests</span>
              </div>
            </div>
            <div className='bg-gray-550 w-[1px] self-stretch hidden sm:block'></div>
            <div
              className='flex items-center'
              aria-label='Erica - one of our guests said'
            >
              <StaticImage
                src='../../../static/avatar.png'
                alt=''
                loading='eager'
                className='w-[40px] h-[40px] rounded-[50%] mr-3'
              />
              <q className='caption text-gray-550 flex-1'>
                Exceptional Japanese cuisine with fresh sushi, rich ramen, and attentive service. Highly recommended!
              </q>
            </div>
          </div>
        </div>
      </div>
      {loadImage ? (
        <div
          aria-hidden='true'
          className='bg-chestnut flex-1 mt-[-100px] relative hidden md:block'
        >
          <StaticImage
            src='../../../static/hero.png'
            alt=''
            loading='eager'
            className='!absolute top-1/2 -translate-y-1/2 right-0 left-[-80px] lg:left-[-120px] xxl:left-0 xxl:bottom-0 xxl:top-0 xxl:translate-y-0'
          />
        </div>
      ) : null}
    </section>
  );
};
