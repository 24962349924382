import React, { useState } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Button, Popover, PopoverContent, PopoverTrigger, Icon, LogoSVG } from '../common';
import { Links } from './links';
import { PAGES } from '../../config/global';

interface IProps {
  variant: 'primary' | 'secondary';
}

const NAV_LINKS = [
  { href: '/#about-us', label: 'About Us', ariaLabel: 'About Us' },
  { href: '/#menu', label: 'Menu', ariaLabel: 'Our Menu' },
  { href: '/#newsletter', label: 'Newsletter', ariaLabel: 'Newsletter' }
];

export const NavBar: React.FC<IProps> = ({ variant }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const openMenu = () => setIsExpanded(true);
  const closeMenu = () => setIsExpanded(false);
  const isSecondary = variant === 'secondary';

  return (
    <nav
      className={`flex items-center justify-between md:justify-start container-x py-[20px] ${
        isSecondary ? 'bg-chestnut' : ''
      }`}
    >
      <div className='md:w-[50%]'>
        <AnchorLink
          to={PAGES.HOME}
          gatsbyLinkProps={{ 'aria-label': 'Home Page' }}
          className='block w-fit'
        >
          <LogoSVG
            className={`accessible-interactive w-[180px] sm:w-[250px] ${isSecondary ? 'text-white' : 'text-black-100 '}`}
            aria-hidden='true'
          />
        </AnchorLink>
      </div>
      <Popover
        open={isExpanded}
        onOpenChange={setIsExpanded}
      >
        <PopoverTrigger asChild>
          <Button
            onClick={openMenu}
            variant={'default'}
            className='md:hidden'
            aria-label={isExpanded ? 'Close Menu' : 'Open Menu'}
          >
            <Icon.Menu
              aria-hidden='true'
              className={`${isSecondary ? 'text-white' : 'text-chestnut'} accessible-interactive`}
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          alignOffset={0}
          className={`${isSecondary ? 'bg-gray-200' : 'bg-chestnut'} py-5 px-5`}
          align='end'
        >
          <Links
            className={`flex flex-col gap-1 ${isSecondary ? 'text-gray-550' : 'text-white'}`}
            onClick={closeMenu}
            links={NAV_LINKS}
          />
        </PopoverContent>
      </Popover>
      <Links
        links={NAV_LINKS}
        className={`hidden md:flex justify-end gap-3 flex-1 z-[10] text-white`}
      />
    </nav>
  );
};
