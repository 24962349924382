import React from 'react';
import { SubscribeForm } from './subscribe-form';
import { useAnimateOnVisible } from '../../hooks';

export const Newsletter = () => {
  useAnimateOnVisible({
    animations: [
      {
        animationClassName: 'animate__zoomIn',
        elementId: 'newsletter-title',
        containerId: 'newsletter-header'
      },
      {
        animationClassName: 'animate__pulse',
        elementId: 'newsletter-subtitle',
        containerId: 'newsletter-header'
      }
    ]
  });

  return (
    <section
      id='newsletter'
      className='bg-beige py-10 container-x flex flex-col gap-10'
    >
      <div
        className='mb-0.5'
        id='newsletter-header'
      >
        <h2
          id='newsletter-title'
          className='heading-1 text-black-100 mb-4 md:mb-2 md:text-center motion-safe:invisible animate__animated'
        >
          Exciting Updates and Exclusive Offers
        </h2>
        <h3
          id='newsletter-subtitle'
          className='paragraph text-gray-550 md:text-center motion-safe:invisible animate__animated animate__delay-1s'
        >
          Subscribe to our newsletter
        </h3>
      </div>
      <SubscribeForm />
    </section>
  );
};
