import React from 'react';
import { Icon } from '../common';
import { Link } from 'gatsby';
import { PAGES } from '../../config/global';

const SOCIAL_PLATFORMS = [
  {
    Icon: Icon.Facebook,
    name: 'Facebook',
    url: 'https://facebook.com'
  },
  {
    Icon: Icon.Instagram,
    name: 'Instagram',
    url: 'https://instagram.com'
  },
  {
    Icon: Icon.Twitter,
    name: 'Twitter',
    url: 'https://twitter.com'
  }
];

const POLICIES = [
  {
    label: 'Privacy Policy',
    url: PAGES.PRIVACY_POLICY
  },
  {
    label: 'Terms Of Service',
    url: PAGES.TERMS_OF_SERVICE
  }
];

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className='bg-chestnut py-5 container-x flex flex-col gap-5 md:gap-0 md:flex-row justify-between'>
      <div>
        <span className='text-white paragraph'>Tokyo Treasures © Copyright {currentYear}. All right reserved</span>
        <ul className='flex flex-col sm:flex-row sm:gap-2.5'>
          {POLICIES.map((policy) => (
            <li
              key={policy.label}
              className='w-fit'
            >
              <Link
                className='link text-beige caption'
                to={policy.url}
              >
                {policy.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <ul
        aria-label='Social media links'
        className='flex gap-2.5'
      >
        {SOCIAL_PLATFORMS.map(({ Icon, name, url }) => (
          <li key={name}>
            <a
              target='_blank'
              href={url}
              aria-label={`Open ${name} in new tab to follow us`}
              className='block'
              rel='noreferrer'
            >
              <Icon
                className='w-6 h-6 text-white'
                title={name}
                aria-hidden='true'
              />
            </a>
          </li>
        ))}
      </ul>
    </footer>
  );
};
