import React from 'react';
import { Button } from '../common';

interface IProps {
  filters: string[];
  activeFilter: string;
  onChange: (filter: string) => void;
}

export const Filters: React.FC<IProps> = ({ filters, activeFilter, onChange }) => {
  return (
    <ul
      aria-label='Categories for menu items'
      className='flex sm:justify-center gap-3 mb-7 horizontally-scrollable'
    >
      {filters.map((filter) => {
        const isActive = activeFilter === filter;
        return (
          <li
            key={filter}
            onClick={() => onChange(filter)}
          >
            <Button
              variant={isActive ? 'activeBadge' : 'badge'}
              aria-current={isActive ? 'true' : undefined}
              aria-label={`Show ${filter} menu items`}
            >
              {filter}
            </Button>
          </li>
        );
      })}
    </ul>
  );
};
