import React from 'react';
import RootLayout from './src/root-layout';
import { GatsbyBrowser } from 'gatsby';
import { initializeGTAG, isGTAGInitialized } from './src/utils';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  return <RootLayout {...props}>{element}</RootLayout>;
};

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  initializeGTAG(process.env.GATSBY_GOOGLE_ANALYTICS_ID!);
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  if (isGTAGInitialized()) {
    const pagePath = location ? location.pathname + location.search + location.hash : undefined;
    window.gtag(`event`, `page_view`, { page_path: pagePath });
  }
};
