import '@fontsource/plus-jakarta-sans/400.css';
import '@fontsource/plus-jakarta-sans/500.css';
import '@fontsource/plus-jakarta-sans/600.css';
import '@fontsource/plus-jakarta-sans/800.css';
import '@fontsource/noto-serif/400.css';
import '@fontsource/noto-serif/500.css';
import '@fontsource/noto-serif/600.css';
import '@fontsource/noto-serif/800.css';
import './animate.css';
import './global.css';
import React from 'react';
import { Footer } from './components';
import { Toaster } from 'sonner';
import { useCookiesConsent } from './hooks';

interface Props {
  children: React.ReactNode;
}

const RootLayout: React.FC<Props> = ({ children }) => {
  useCookiesConsent();
  return (
    <>
      {children}
      <Footer />
      <Toaster />
    </>
  );
};

export default RootLayout;
