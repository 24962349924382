import { FaXTwitter, FaMeta } from 'react-icons/fa6';
import { FaInstagram } from 'react-icons/fa';
import { IoMenu } from 'react-icons/io5';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

export default {
  Facebook: FaMeta,
  Twitter: FaXTwitter,
  Instagram: FaInstagram,
  Menu: IoMenu,
  LeftArrow: IoIosArrowBack,
  RightArrow: IoIosArrowForward
};
