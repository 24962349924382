import React, { useState } from 'react';
import {
  Button,
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  Card
} from '../common';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useCarouselDots } from '../../hooks';
import { IMenuItem } from '../../config/menu';
import { IFileNode } from '../../types';
import { graphql, useStaticQuery } from 'gatsby';

interface IProps {
  activeFilter: string;
  items: IMenuItem[];
}

const MENU_IMAGES_QUERY = graphql`
  {
    allFile(filter: { relativeDirectory: { eq: "menu" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
        name
      }
    }
  }
`;

const getImageData = (imageFiles: IFileNode[], fileName: string) =>
  imageFiles.find((file) => file.name === fileName)?.childImageSharp?.gatsbyImageData ?? null;

export const Items: React.FC<IProps> = ({ activeFilter, items }) => {
  const {
    allFile: { nodes }
  } = useStaticQuery(MENU_IMAGES_QUERY);
  const imageFiles: IFileNode[] = nodes;

  const [carouselApi, setCarouselApi] = useState<CarouselApi>();
  const { selectedIndex, scrollSnaps, onDotClick } = useCarouselDots(carouselApi);

  return (
    <>
      <Carousel
        setApi={setCarouselApi}
        opts={{
          slidesToScroll: 'auto'
        }}
        className='sm:w-[calc(100%-120px)] md:w-[calc(100%-140px)]'
        aria-label={`${activeFilter} menu items carousel`}
      >
        <CarouselContent className='md:-ml-5'>
          {items.map((item, index) => {
            const imageData = getImageData(imageFiles, item.previewImageName);
            return (
              <CarouselItem
                key={item.name + index}
                className='md:basis-1/2 xl:basis-1/3 md:pl-5'
                aria-label={`Item ${index + 1} of ${imageFiles.length}`}
              >
                <Card className='bg-white h-full flex flex-col'>
                  {imageData ? (
                    <GatsbyImage
                      image={imageData}
                      alt={''}
                      className='mb-3.5 mt-1'
                    />
                  ) : null}
                  <div className='flex flex-col px-4 pb-4 flex-1'>
                    <span className='capitalize heading-3 text-black-100 mb-2.5'>{item.name}</span>
                    <p className='paragraph text-gray-550 mb-2.5'>{item.description}</p>
                    <span className='text-black-100 heading-3 mt-auto'>${item.price}</span>
                  </div>
                </Card>
              </CarouselItem>
            );
          })}
        </CarouselContent>
        <CarouselPrevious className='hidden sm:inline-flex' />
        <CarouselNext className='hidden sm:inline-flex' />
      </Carousel>
      <ol
        aria-hidden='true'
        className='flex justify-center items-center gap-2.5 mt-7 horizontally-scrollable'
      >
        {scrollSnaps.map((_, index) => {
          const isActive = selectedIndex === index;
          return (
            <li
              key={index}
              className='block'
            >
              <Button
                tabIndex={-1}
                onClick={() => onDotClick(index)}
                variant={isActive ? 'activeDot' : 'dot'}
              />
            </li>
          );
        })}
      </ol>
    </>
  );
};
