import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../../utils';

const variants = cva(
  'inline-flex items-center justify-center whitespace-nowrap capitalize disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        badge: 'hover:bg-beige hover:text-chestnut bg-gray-200 text-black-100 py-1.5 px-2.5 rounded-[4px] accessible-interactive',
        activeBadge: 'bg-beige text-chestnut py-1.5 px-2.5 rounded-[4px] accessible-interactive',
        icon: 'hover:bg-beige bg-white w-[40px] h-[40px] rounded-[50%] text-chestnut',
        dot: 'hover:bg-gray-550 bg-gray-200 h-[15px] w-[15px] rounded-[50%]',
        activeDot: "bg-gray-550 h-[15px] w-[15px] rounded-[50%]",
        outlined: "py-4 px-14 bg-transparent border-[2px] rounded-[4px]",
        default: "py-1.5 px-2.5 rounded-[4px] accessible-interactive"
      }
    },
    defaultVariants: {
      variant: 'badge'
    }
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof variants> {
  asChild?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(variants({ variant, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';