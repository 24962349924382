import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { COOKIES, PRODUCTION_ENV } from '../config/global';

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

export const isWindowDefined = () => typeof window !== 'undefined';

export const isGTAGInitialized = () => isWindowDefined() && window.gtag !== undefined;

export const isProd = () => process.env.NODE_ENV === PRODUCTION_ENV;

export const isDNTEnabled = () => navigator.doNotTrack === '1' || window.doNotTrack === '1';

export const initializeGTAG = (trackingId: string) => {
  if (isWindowDefined() && isProd() && !isDNTEnabled()) {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
    if (localStorage.getItem(COOKIES.KEY) !== COOKIES.ACCEPTED_VALUE) {
      window.gtag('consent', 'default', {
        ad_storage: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'denied',
        ad_user_data: 'denied'
      });
    }
    window.gtag('config', trackingId, { send_page_view: false });
  }
};
