export interface IMenuItem {
  name: string;
  description: string;
  price: number;
  previewImageName: string;
  category: string;
}

export const MENU_ITEMS: IMenuItem[] = [
  {
    name: 'California Roll',
    price: 11.99,
    description: 'Fresh crab, avocado, cucumber, and spicy mayo, wrapped in seaweed and rice.',
    category: 'Sushi',
    previewImageName: 'item-1'
  },
  {
    name: 'Spicy Tuna Roll',
    price: 13.99,
    description: 'Spicy tuna, cucumber, and avocado, topped with sesame seeds and spicy sauce.',
    category: 'Sushi',
    previewImageName: 'item-2'
  },
  {
    name: 'Shoyu Ramen',
    price: 15.99,
    description: 'Savory soy-based broth with tender pork slices, bamboo shoots, and a soft-boiled egg.',
    category: 'Ramen',
    previewImageName: 'item-3'
  },
  {
    name: 'Chicken Udon',
    price: 14.99,
    description: 'Thick udon noodles in a savory broth with grilled chicken, mushrooms, and vegetables.',
    category: 'Udon',
    previewImageName: 'item-2'
  },
  {
    name: 'Mochi Dango',
    price: 8.99,
    description: 'Chewy mochi rice cakes skewered and coated with a sweet soy sauce glaze.',
    category: 'Danggo',
    previewImageName: 'item-3'
  },
  {
    name: 'Beef Udon',
    price: 16.99,
    description: 'Tender beef slices in a rich broth with udon noodles, bok choy, and mushrooms.',
    category: 'Udon',
    previewImageName: 'item-1'
  },
  {
    name: 'Matcha Dango',
    price: 9.99,
    description: 'Mochi rice cakes flavored with matcha green tea and drizzled with a sweet syrup.',
    category: 'Danggo',
    previewImageName: 'item-2'
  }
];

export const ALL = 'all';
export const MENU_CATEGORIES = [ALL, ...new Set(MENU_ITEMS.map((item) => item.category))];
