import React, { FormEvent } from 'react';
import { Input, Button, Label } from '../common';

export const SubscribeForm = () => {
  const onSubscribe = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('Subscribed');
  };

  return (
    <form
      className='flex flex-col md:flex-row md:justify-center gap-3'
      onSubmit={onSubscribe}
      aria-label='Subscribe to Newsletter Form'
    >
      <div className='md:w-[35%] relative'>
        <Label
          htmlFor='email'
          className='sr-only'
        >
          Email
        </Label>
        <Input
          placeholder='john@gmail.com'
          id='email'
          type='email'
          className='border-gray-50 w-full h-full'
          required
        />
      </div>
      <Button
        type='submit'
        variant={'outlined'}
        className='text-chestnut border-chestnut paragraph h-[52px] md:h-full hover:bg-chestnut hover:text-white'
      >
        Subscribe
      </Button>
    </form>
  );
};
