import { useEffect, useRef } from 'react';

interface IAnimation {
  elementId: string;
  containerId: string;
  animationClassName: string;
}

type Options = {
  animations: IAnimation[];
};

export function useAnimateOnVisible(options: Options) {
  const { animations = [] } = options;
  const observer = useRef<null | IntersectionObserver>(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const containerId = entry.target.id;
          const animationConfigs = animations.filter((a) => a.containerId === containerId);
          const isIntersecting = entry.intersectionRatio >= 0.5;
          animationConfigs.forEach((animationConfig) => {
            const elementToAnimate = document.querySelector(`#${animationConfig.elementId}`);
            elementToAnimate?.classList?.toggle('motion-safe:invisible', !isIntersecting);
            elementToAnimate?.classList?.toggle(animationConfig.animationClassName, isIntersecting);
          });
          if (isIntersecting) {
            observer?.current?.unobserve(entry.target);
          }
        });
      },
      { threshold: [0.1, 0.5] }
    );

    animations.forEach((animation) => {
      const containerElement = document.querySelector(`#${animation.containerId}`);
      if (containerElement) {
        observer.current?.observe(containerElement);
      }
    });

    return () => observer.current?.disconnect();
  }, [animations]);
}
