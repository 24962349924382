export const COOKIES = {
  KEY: 'cookies-consent',
  ACCEPTED_VALUE: 'YES',
  DECLINED_VALUE: 'NO'
};

export const PRODUCTION_ENV = 'production';

export const POLICIES = {
  COMPANY_NAME: 'Tokyo Treasures',
  EMAIL: 'tokyo-treasures@gmail.com',
  LAST_UPDATED: new Date('2024-09-20T00:51:06.888Z').toLocaleDateString()
};

export const PAGES = {
  HOME: '/',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_SERVICE: '/terms-of-service'
};
