import { Link } from 'gatsby';
import React from 'react';
import { Button } from './button';
import { PAGES } from '../../config/global';

interface IProps {
  onDecline: () => void;
  onAccept: () => void;
}

export const CookiesConsent: React.FC<IProps> = ({ onAccept, onDecline }) => {
  return (
    <div className='caption'>
      <div>
        <span className='text-black-100'>
          We use non-essential cookies to improve user experience and analyze website traffic. For more information,
          please read the {''}
        </span>
        <Link
          to={PAGES.PRIVACY_POLICY}
          className='link text-chestnut'
        >
          privacy policy
        </Link>
        .
      </div>
      <div className='flex justify-end gap-2.5 items-center mt-2.5'>
        <Button
          variant={'default'}
          className='bg-chestnut text-white hover:opacity-80'
          onClick={onDecline}
        >
          Decline
        </Button>
        <Button
          variant={'default'}
          className='bg-gray-50 text-black-100 hover:opacity-70'
          onClick={onAccept}
        >
          Accept
        </Button>
      </div>
    </div>
  );
};
