import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useAnimateOnVisible } from '../../hooks';

export const AboutUs = () => {
  useAnimateOnVisible({
    animations: [
      {
        animationClassName: 'animate__slideInRight',
        elementId: 'mission-heading',
        containerId: 'about-us-info'
      }
    ]
  });

  return (
    <section
      id='about-us'
      className='flex flex-col lg:flex-row lg:items-center justify-between gap-8 py-10 lg:py-20'
    >
      <StaticImage
        src='../../../static/about-us.png'
        alt=''
        className='bg-beige lg:w-[45%]'
        objectFit='contain'
      />
      <div
        className='flex-1 container-x lg:pl-0 overflow-hidden'
        id='about-us-info'
      >
        <h2 className='heading-3 text-gray-550'>About Us</h2>
        <h3
          id='mission-heading'
          className='heading-1 mb-3 text-black-100 animate__animated'
        >
          Our mission is to bring the authentic flavors of Japan to you
        </h3>
        <p className='paragraph text-gray-550'>
          We are dedicated to crafting each dish with passion and precision, ensuring a memorable dining experience that
          celebrates the rich traditions of Japanese culinary artistry
        </p>
      </div>
    </section>
  );
};
