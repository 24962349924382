import React, { useRef, useEffect } from 'react';
import { toast } from 'sonner';
import { CookiesConsent } from '../components/common';
import { isDNTEnabled, isGTAGInitialized } from '../utils';
import { COOKIES } from '../config/global';

type ConsentStatus = 'granted' | 'denied';

interface IConsentConfig {
  ad_storage?: ConsentStatus;
  ad_personalization?: ConsentStatus;
  analytics_storage?: ConsentStatus;
  ad_user_data?: ConsentStatus;
}

const updateGTAGConsent = (consentConfig: IConsentConfig) => {
  if (isGTAGInitialized()) {
    window.gtag('consent', 'update', consentConfig);
  }
};

export const useCookiesConsent = () => {
  const cookieConsentToastId = useRef<null | number | string>(null);

  const onCookiesAccept = () => {
    localStorage.setItem(COOKIES.KEY, COOKIES.ACCEPTED_VALUE);
    updateGTAGConsent({ analytics_storage: 'granted' });
    toast.dismiss(cookieConsentToastId.current!);
  };

  const onCookiesDecline = () => {
    localStorage.setItem(COOKIES.KEY, COOKIES.DECLINED_VALUE);
    toast.dismiss(cookieConsentToastId.current!);
  };

  useEffect(() => {
    const cookiesConsent = localStorage.getItem(COOKIES.KEY);
    if (cookiesConsent === null && !isDNTEnabled()) {
      cookieConsentToastId.current = toast(
        <CookiesConsent
          onAccept={onCookiesAccept}
          onDecline={onCookiesDecline}
        />,
        {
          duration: Infinity,
          className: 'bg-beige'
        }
      );
    }
  }, []);
};
